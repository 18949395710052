/* .wise9ja-disp {
    background: #F2F2F2;
} */
@tailwind base;
@tailwind components;
@tailwind utilities;

.avatar-cover {
    width: 50px;
    height: 50px;
    background: #1A1A1A;
    border-radius: 50%;
}

.full-height.display-page {
    height: calc(100dvh - 8rem);
    height: calc(100dvh - 5rem);
    position: relative;
    background: #F4F4F4;
    background: #F7F7F7;
    background: #ECECEC;
}

.flex-block {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.wise9ja-disp .greeting-display {
    padding: 20px 0px;
}

.wise9ja-disp .grid-flex {
    align-items: center;
}

.wise9ja-disp .wise9ja-card-detail {
    background: #E4E4E4;
    background: linear-gradient(109.6deg, rgb(61, 121, 176) 11.3%, rgb(35, 66, 164) 91.1%);
    padding: 5% 0px;
    padding-top: 1.5%;
}

/* .wise9ja-content .wise9ja-disp .wise9ja-card-detail {
    background: #E4E4E4;
} */

.wise9ja-disp .wise9ja-inside-card {
    background: #1A1A1A;
    width: 100%;
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 20px;
}

.wise9ja-disp .wise9ja-inside-card svg {
    stroke: #fff;
    height: 1.2rem;
}

.wise9ja-disp .wise9ja-inside-card button.btn-white {
    border-radius: 50px;
    font-size: 1.2rem;
    padding: 10px 25px;

}

.wise9ja-disp .wise9ja-inside-card .grid-flex {
    align-items: center;
}

.wise9ja-disp .wise9ja-inside-card h5,
.wise9ja-disp .wise9ja-inside-card a {
    font-size: 1.4rem;
    color: #fff;
    /* text-decoration: none; */
}

.wise9ja-disp .wise9ja-icon-cover {
    background: #1A1A1A;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    margin-bottom: 10px;
}

/* .wise9ja-disp .card-action-box {
    background: #1C4F80;
    padding: 10px 0px;
    border-radius: 6px;
} */

/* .wise9ja-disp .card-action-box p {
    color: #EEEEEE;
}

.wise9ja-disp .card-action-box svg {
    stroke: #EEEEEE;
} */

.wise9ja-disp .wise9ja-icon-cover svg {
    height: 1.8rem;
    stroke: #E4E4E4;
}

.wise9ja-disp .wise9ja-icon-cover ion-icon {
    font-size: 2rem;
}

.wise9ja-amount-box h3 {
    color: #fff;
    font-size: 3rem;
}

.wise9ja-disp .wise9ja-card-detail p {
    /* text-align: center; */
    font-family: 'Gilroy Bold';
    font-size: 1.2rem;
}

.wise9ja-disp .wise9ja-card-detail .top-welcome-address p {
    font-family: 'Gilroy Medium';
}

.grid-card-actions p {
    color: #000;
    text-align: center;
}

.cart-content.display-main-content {
    padding-bottom: 50%;
    padding-bottom: 30%;
}

.price-listing .grid-flex h4 {
    font-size: 1.4rem;
}


.notifications-box .current-history-display {
    display: grid;
    grid-template-columns: 1fr 6fr;
}

.notifications-box .current-history-display p {
    font-size: 1.1rem;
    line-height: 1.8;
    font-family: 'Gilroy Medium';
}

.notifications-box ul {
    margin-bottom: 0px;
    display: flex;
    grid-gap: 5px;
}

.notifications-box ul li {
    list-style: none;
    font-size: 1.1rem;
    font-family: 'Gilroy Medium';
}

.notifications-box .current-history-display h3 {
    margin-bottom: 5px;
}

.notifications-box {
    background: #fff;
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 20px;
}

.gray-page {
    background: #EEEEEE;
}


.center-modal-content {
    text-align: center;
}

.modal-content h4 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.modal-content p {
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 10px;
    font-family: 'Gilroy Regular';
}

.modal-content svg {
    height: 5rem;
    margin-bottom: 20px;
}

.page-intro {
    text-align: center;
}

.page-intro .page-intro-box {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #D0D0D0;
    margin: 0px auto;
    margin-bottom: 10px;
}

.page-intro h4 {
    font-size: 1.8rem;
    margin-bottom: 5px;
}

.page-intro p {
    font-size: 1.6rem;
    margin: 0px auto;
    display: block;
    width: 80%;
    line-height: 1.6;
}

.faq_container ul li,
.faq_container p {
    font-size: 1.3rem;
    line-height: 1.8;
    list-style: inside;
}

.faq_container h5 {
    font-size: 1.4rem;
    margin-top: 10px;
    margin-bottom: 10px;
}

.faq_container th {
    font-size: 1.2rem;
}

.faq_container td {
    line-height: 1.4;
    font-size: 1.2rem;
}

.faq_container table {
    margin-top: 20px;
}

.faq_container td.column2 {
    /* margin-bottom: 20px !important;
    display: block; */
    padding: 10px 0px;
}

.how-to img {
    width: 20%;
    height: auto;
    margin: 0px auto;
    display: block;
    margin-bottom: 10px;
}

.how-to {
    background: #E4E4E4;
    height: 100%;
}

.how-to p.center {
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.6;
    font-family: 'Gilroy Medium';
    margin-bottom: 0px;
}

.how-to .white-bg {
    background: #fff;
    padding: 20px 3% 15px;
}

.how-to .white-bg.faqs {
    padding: 20px 3% 10px;
}

.how-to .white-bg.faqs h5 {
    margin-bottom: 10px;
}

.how-to .white-bg ul {
    margin-bottom: 0px;
}

.gift-cover {
    background: #fff;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
}

.how-to .profile-first-sect {
    align-items: center;
}

.gift-cover img {
    width: 50%;
    margin-bottom: 0px;
}

.how-to .white-bg ul li {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    line-height: 1.6;
    margin-bottom: 20px;
    list-style: inside;
}

.how-to .white-bg ul li:last-child {
    margin-bottom: 0px;
}

.how-to.referral .white-bg ul li {
    line-height: 1.8;
}

.faq-box .ant-collapse-header-text span,
.how-to .white-bg ul li span {
    background: var(--base-color);
    height: 30px;
    width: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: 'Gilroy Medium';
    font-size: 1.2rem;
}

.faq-box .ant-collapse-header-text {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.faq-box .ant-collapse .ant-collapse-item .ant-collapse-header {
    padding-left: 0px;
}

.faq-box .ant-collapse .ant-collapse-item .ant-collapse-header p {
    font-size: 1.3rem;
    font-family: 'Gilroy Medium';
}

.faq-box .ant-collapse .ant-collapse-item .ant-collapse-content-box {
    padding-left: 0px;
}

.faq-box .ant-collapse .ant-collapse-item .ant-collapse-content-box p {
    font-size: 1.3rem;
    font-family: 'Gilroy Medium';
}

.how-to .white-bg h5 {
    font-size: 1.3rem;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.white-bg.faqs {
    border-top: 3px solid #D0D0D0;
}

.how-to .white-bg .grid-flex p,
.how-to .white-bg ul li p {
    font-size: 1.3rem;
    font-family: 'Gilroy Medium';
}

.how-to .white-bg .grid-flex p {
    line-height: 1.6;
}

.how-to .link-blue-cover {
    background: var(--base-color);
    height: 30px;
    width: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.how-to .bg-blue {
    background: var(--base-color);
    height: 40px;
    width: 40px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.how-to .grid-flex {
    align-items: center;
}

.how-to .white-bg .grid-flex .grid-flex {
    grid-gap: 10px;
}

.how-to .white-bg .grid-flex .grid-flex p {
    font-size: 1.3rem;
    font-family: 'Gilroy Medium';
}

.how-to .white-bg .grid-flex ion-icon {
    font-size: 1.5rem;
}

.how-to h3 {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 20px;
}

.how-to .link p.center {
    font-size: 1.3rem;
}

.modal-disp.empty .extra-detail img {
    width: 15%;
    height: auto;
    margin: 0px auto;
    display: block;
}

.modal-disp.empty .extra-detail p {
    font-size: 1.4rem;
    width: 60%;
    line-height: 1.6;
    margin: 0px auto;
    display: block;
    text-align: center;
    margin-bottom: 20px;
}

.detail.settings-display {
    height: 100dvh;
}

.detail.settings-display .notification svg {
    stroke: #fff;
}

.detail.settings-display .settings-detail-summary ul li.grid-flex {
    padding: 0px;
    line-height: 4.0;
}

.detail.settings-display .extra-detail button {
    margin-top: 40px;
}

.profile-first-sect {
    height: 25rem;
}

.how-to .profile-first-sect h3 {
    color: #fff;
    font-size: 1.6rem;
    margin-bottom: 0px;
}

.detail.settings-display .profile-first-sect {
    height: 25%;
}



.form-explanation {
    font-size: 1.4rem;
    font-family: 'Gilroy Medium';
    line-height: 1.8;
}

.main-center-message.inline-status-box p {
    width: 90%;
}

.bill_logo {
    width: auto;
    height: 2rem;

    width: 20px;
    height: 2rem;
}

.bill-inline {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}