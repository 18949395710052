@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --base-color: #0D60D8;
    --base-colo: #003032;
    --gray-color: #F8F8F8;
    --green: #258635;
    --green-shade: #AEE3C5;
}

@font-face {
    font-family: 'Gilroy Regular';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Regular.ttf');
}

@font-face {
    font-family: 'Gilroy Medium';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Medium.ttf');
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Bold.ttf');
}

* {
    margin: 0px;
    padding: 0px;
    /* overflow-x: hidden; */
    box-sizing: border-box;
}

body,
html {
    margin: 0px;
    padding: 0px;
    font-size: 10px;
    height: 100dvh;
    background-color: var(--gray-color);
    background: #fff;
    /* overflow: hidden; */
    font-family: 'Gilroy Regular';
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button {
    font-family: 'Gilroy Bold';
    font-weight: normal;
    color: #000;
}

.contain {
    padding: 0% 1.5%;
}

.container {
    padding: 0% 3%;
}

.grid-flex {
    display: flex;
    justify-content: space-between;
}

/* layout */

.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.grid-5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
}

.mt_6 {
    margin-top: 12%;
}


.mt_20 {
    margin-top: 20%;
}

.mt_5 {
    margin-top: 10%;
}

.mt_15 {
    margin-top: 15%;
}

.mt_13 {
    margin-top: 13%;
}

.mt_4 {
    margin-top: 8%;
}

.mt_3 {
    margin-top: 6%;
}

.mt_2 {
    margin-top: 4%;
}

.mt_1 {
    margin-top: 2%;
}

.pt_5 {
    padding-top: 10%;
}

.mb_3 {
    margin-bottom: 6%;
}

.mb_2 {
    margin-bottom: 4%;
}


/* button */

.btn {
    padding: 20px 25px;
    font-size: 1.4rem;
}

.btn-white {
    background: #fff;
    width: 100%;
    padding: 15px 25px;
    border: 1px solid #fff;
    border-radius: 2px;
}

.btn-base {
    background: var(--base-color);
    width: 100%;
    border: 1px solid var(--base-color);
    border-radius: 4px;
    color: #fff;
}

.btn-border-base {
    background: transparent;
    width: 100%;
    border: 2px solid var(--base-color);
    border-radius: 4px;
    color: var(--base-color);
}

.btn-gray {
    background: var(--gray-color);
    width: 100%;
    padding: 15px 25px;
    border: 1px solid var(--gray-color);
    border-radius: 4px;
    color: #111;
}

.btn-border-gray {
    background: transparent;
    width: 100%;
    padding: 15px 25px;
    border: 2px solid var(--gray-color);
    border-radius: 4px;
    color: #111;
}

.btn-blank {
    background: transparent;
    width: 100%;
    padding: unset;
    border: unset;
    border-radius: 2px;
    color: var(--base-color);
}
.btn-cancel {
    background: transparent;
    width: 100%;
    padding: 15px 25px;
    border: unset;
    border-radius: 2px;
    color: #000;
}

.btn-red {
    background: #B60001;
    border: 1px solid #B60001;
    width: 100%;
    border-radius: 4px;
    color: #fff;
}

.btn-green {
    background: var(--green);
    border: 1px solid var(--green);
    width: 100%;
    display: block;
    padding: 13px 25px;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.8;
    color: #fff;
    border-radius: 4px;
}

.btn-blue {
    background: var(--base-color);
    border: 1px solid var(--base-color);
    width: 100%;
    display: block;
    padding: 13px 25px;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.8;
    color: #fff;
    border-radius: 4px;
}

.btn-border-green {
    background: transparent;
    border: 2px solid var(--green);
    width: 100%;
    display: block;
    padding: 12px 25px;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.8;
    color: var(--green);
    border-radius: 4px;
}

.btn-border-blue {
    background: transparent;
    border: 2px solid var(--base-color);
    width: 100%;
    display: block;
    padding: 13px 25px;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.8;
    color: var(--base-color);
    border-radius: 4px;
}

/* top-circle */
.base-bg {
    height: 100%;
    background: var(--base-color);
}

.top-circle-cover {
    width: 100%;
    position: relative;
    overflow-y: visible;
    overflow-x: hidden;
    position: absolute;
    z-index: 10;
    height: calc(290px + 10rem - 30px);
    height: 250px;
}


.homepage.home-display .content-display-content {
    height: calc(100% - 150px);
    height: 100%;
    padding-top: 170px;
}

.top-circle-cover .circle-content {
    width: calc(100% - 3%);
    width: 100%;
    margin: 0px auto;
    height: 10rem;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -30px;
    border-radius: 8px;
    z-index: 100;
    padding-left: 18%;
    padding-right: 18%;
    overflow: visible;
}

.top-circle-cover .circle-content .white-bg {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .2);
}

.top-circle-cover .circle-content .grid-flex {
    padding: unset;
}

.top-circle-cover .circle-content .grid-flex svg {
    height: 1.6rem;
    width: auto;
}

.top-circle-cover .circle-content .grid-flex svg.pocket {
    height: 1.4rem;
}

.top-circle-cover .circle-content .grid-flex button {
    background: unset;
    border: none;
}

.top-circle-cover .circle-content p,
.top-circle-cover .circle-content a {
    font-size: 1.3rem;
    text-decoration: none;
    font-family: 'Gilroy Medium';
    display: flex;
    align-items: center;
    grid-gap: 10px;
    color: var(--base-color);
}

.top-circle-cover .circle-content h2 {
    font-size: 2.1rem;
}

.top-circle-cover .circle-content .grid-flex.amount-display button {
    font-size: 1.2rem;
    background: var(--base-color);
    border: 1px solid var(--base-color);
    padding: 12px 25px;
    border-radius: 50px;
    color: #fff;
    line-height: 1;
}

.top-circle-cover .circle-content .grid-flex.amount-display {
    margin-top: 30px !important;
}

.top-circle-cover .circle-content .grid-flex.amount-display {
    margin-top: 20px;
}

.home-display .circle-content ul.muted-list {
    display: flex;
    grid-gap: 5px;
    margin-bottom: 10px;
}

.home-display .circle-content ul.muted-list li {
    font-size: 1.3rem;
    list-style: none;
    color: #808080;
    font-family: 'Gilroy Medium';
}

.home-display .circle-content p {
    color: #808080;
}

.home-display .circle-content h3 {
    color: var(--base-color);
    text-align: unset;
    font-size: 1.4rem;
    margin-bottom: 5px;
}

.dash-restaurant-board {
    height: 55px;
    width: 55px;
    background: #017A51;
    border-radius: 50%;
}

.dash-restaurant-cover {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

/* .top-circle-cover .circle-content .circle-content-main {
    width: 100%;
    background: #fff;
    height: 100%;
    padding-left: 18%;
} */

.top-circle {
    height: 200px;
    height: 150px;
    width: 150%;
    position: absolute;
    left: -25%;
    right: -25%;
    border-radius: 0px 0px 50% 50%;
    background: var(--base-color);
    overflow: visible;
}

.top-circle-cover.short,
.top-circle-cover.short .top-circle {
    height: 150px;
}

.body-circle-cover {
    width: 100%;
    position: relative;
    height: 300px;
    height: calc(100vh - 7rem);
    overflow-x: hidden;
}

.body-circle-cover .body-circle {
    height: 100%;
    width: 150%;
    position: absolute;
    left: -25%;
    right: -25%;
    border-radius: 50% 50% 0px 0px;
    background: #fff;
}

.body-circle-cover .body-circle .body-content {
    padding-left: 18%;
    padding-right: 18%;
}

.top-circle .grid-flex {
    align-items: center;
    padding-left: 18%;
    padding-right: 18%;
}

.top-circle h3 {
    text-align: center;
    font-size: 2rem;
    color: #fff;
}

.top-circle .top-display {
    padding-top: 10px;
}

.nav-cover .grid-flex {
    align-items: center;
    padding: 10px 3%;
}

.nav-cover .grid-flex h3 {
    align-items: center;
    font-size: 2rem;
}

.circle-header-icons .icon-box {
    height: 50px;
    width: 50px;
    flex-direction: column-reverse;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle-header-icons .icon-box svg {
    height: 20px;
    width: auto;
    transform: rotate(-30deg);
}

.circle-header-icons .icon-box svg.normal {
    transform: none;
}

.nav-cover {
    height: 7rem;
    background: var(--base-color);
}

.nav-cover h3 {
    color: #fff;
}

/* homepage */
.extra-data-message {
    background: #083F75;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    height: 10rem;
}

.extra-data-message.red {
    background: #A9411D;
}

.extra-data-message h3 {
    color: #fff;
    padding-bottom: 10px;
    font-size: 1.4rem;
}

.extra-data-message p {
    color: #F3F6F5;
    margin-bottom: 0px;
    padding-bottom: 0px;
    line-height: 1.6;
    font-size: 1.4rem;
}

.extra-data-message .extra-div {
    position: absolute;
    width: 30%;
    height: 320%;
    background: #1C4F80;
    top: -60%;
    transform: rotate(-70deg);
}

.extra-data-message.red .extra-div {
    background: #BA522D;
}

.extra-data-message .extra-div._2 {
    width: 30%;
    height: 170%;
    top: 0%;
    left: 35%;
    transform: unset;
}

.extra-data-message .extra-div._3 {
    position: absolute;
    width: 30%;
    height: 310%;
    top: -60%;
    right: 0px;
    transform: rotate(70deg);
}

.extra-data-message .extra-data-content {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0px 15px;
}

.promo-info {
    /* border-radius: 8px; */
    width: 100%;
}

.promo-info img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.promo-info._2 {
    background: #FFBFC1;
}

.promo-info._3 {
    background: #652A70;
}

.promo-info h4 {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.promo-info p {
    font-size: 1.4rem;
    color: #DB2544;
    margin-bottom: 10px;
}

.promo-info p.unimportant {
    font-size: 1.2rem;
    color: #096D55;
}

.promo.form-group {
    background: #F5F6F7;
    display: grid;
    grid-template-columns: 3fr 1fr;
    border-radius: 50px;
    padding: 5px 10px;
}

.promo.form-group input {
    background: transparent;
    height: 5rem;
    border-radius: 50px;
    border: unset;
}

.top-selection-bar {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 0px;
}

.top-selection-bar .select-bar .active-inner-text {
    padding: 10px 15px;
    border-radius: 50px;
    font-size: 1.2rem;
    background: #808080;
}

.top-selection-bar .select-bar {
    border: 3px solid #fff;
    border: none;
    padding: 4px;
    border-radius: 50px;
}

.top-selection-bar .select-bar.active-bar {
    border: 3px solid var(--base-color);
}

.top-selection-bar .select-bar h4 {
    color: #fff;
}

.promo.form-group button {
    height: 5rem;
    width: 100%;
    border-radius: 50px;
    background: var(--base-color);
    color: #fff;
    border: 1px solid var(--base-color);
}

/* welcome-display */
.welcome-display {
    height: 100dvh;
    margin: 0px;
    box-sizing: border-box;
    position: relative;
    background: white;
    /* background: var(--base-color); */
}

.welcome-display .welcome-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.welcome-buttons.bottom-button {
    border: none;
    /* background: #fff; */
    padding: 30px 1.5%;
}

.welcome-buttons.bottom-button a {
    border: none;
    /* background: #fff; */
}

.welcome-display .welcome-buttons {
    position: absolute;
    bottom: 30px;
    width: 100%;
}

.welcome-display .welcome-buttons-content {
    padding: 10px;
}

.welcome-buttons a {
    display: block;
    padding: 15px 25px;
    font-size: 1.4rem;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    border: 2px solid #fff;
}

.welcome-buttons a.border-green {
    color: #000;
    width: 100%;
    margin-top: 10px;
    border: 1px solid green;
}

/* 
.welcome-buttons {
    margin-top: 20%;
} */

.welcome-buttons .welcome-buttons-content {
    display: block;
}

.auth-btn-blank {
    display: block;
    padding: 15px 25px;
    font-size: 1.4rem;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    border: 1px solid var(--green);
    color: var(--green);
    width: 100%;
}

.welcome-buttons a.main-button {
    background: var(--base-color);
    color: #fff;
    margin-bottom: 10px;
}

.welcome-display .welcome-spacing {
    padding: 0px 10px;
    margin: 0px auto;
    margin-top: -18rem;
    display: block;
}

.welcome-display .welcome-spacing p {
    margin-bottom: 0px;
    font-size: 1.3rem;
    line-height: 1.8;
    color: var(--base-color);
    color: #000;
    font-family: 'Gilroy Medium';
    text-align: center;
    width: 0px auto;
    display: block;
}

.welcome-display .welcome-spacing h3 {
    font-size: 2.7rem;
    margin-bottom: 5px;
    text-align: center;
    color: #000;
}

/* .welcome-display img {
    width: auto;
    height: 20rem;
    margin: 0px auto;
    margin-bottom: 40px;
    display: block;
} */

.carousel-message-display ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-bottom: 10px;
}

.carousel-message-display ul li {
    display: block;
    height: 2px;
    width: 100%;
    background: #fff;
}

.carousel-message-display ul li.active {
    background: var(--base-color);
}

/* first-block-display */
.first-block-display {
    height: 100dvh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--base-color);
}

.first-block-display img {
    height: auto;
    width: 50%;
}


/* .profile-display .settings-list {
    height: 60%;
    background: #fff;
    border-radius: 30px 30px 0px 0px;
    width: 100%;
    padding-top: 30px;
} */

/* .profile-display .settings-detail-summary {
    text-align: center;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-display .settings-detail-summary .settings-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0px auto;
    display: block;
    background: #fff;
    margin-bottom: 20px;
} */

/* .profile-display .settings-detail-summary h3 {
    color: #fff;
    font-size: 2rem;
} */

.profile-display .settings-detail-summary .settings-inner-detail {
    margin-bottom: 15px;
}

.profile-display .settings-detail-summary .settings-inner-detail p {
    display: inline-block;
    color: rgba(253, 253, 253, .4);
    font-size: 1.4rem;
    line-height: 1.8;
}

.profile-display .settings-detail-summary .settings-inner-detail p:first-child {
    padding-right: 20px;
}

.grid-2 .profile-sum-message,
.profile-display .settings-detail-summary .grid-3 .profile-sum-message {
    padding: 10px 5px;
    border: 1px solid rgba(253, 253, 253, .4);
    text-align: unset;
    border-radius: 4px;
}

.grid-2 .profile-sum-message p,
.profile-display .settings-detail-summary .grid-3 .profile-sum-message p {
    color: rgba(253, 253, 253, .4);
    font-size: 1.2rem;
}

.grid-2 .profile-sum-message h2,
.profile-display .settings-detail-summary .grid-3 .profile-sum-message h2 {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.profile-display .settings-detail-summary .grid-3 {
    grid-gap: 5px;
}

.mini-header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
}

.mini-header svg {
    height: 2rem;
}

.mini-header h4 {
    margin: 0px;
    font-size: 1.4rem;
}

/* .profile-display */

.footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: #fff;
    z-index: 1000;
    padding: 15px 0px;
    /* padding-top: 0px; */
    /* box-shadow: 0px 2px 60px rgba(0, 0, 0, .2); */
    /* height: 6.5rem; */
    border-top: 3px solid rgba(5, 5, 5, 0.06);
    /* border-radius: 10px 10px 0px 0px; */
}

.footer .footer-display ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.footer .footer-display ul li {
    list-style: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.footer .footer-display ul li svg {
    height: 20px;
    width: auto;
}

.footer .footer-display ul li a {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.1rem;
    text-decoration: none;
    color: #808080 !important;
    padding-top: 0px;
    font-family: 'Gilroy Regular';
    position: relative;
}

.footer .footer-display ul li a h3 {
    color: #808080 !important;
    font-size: 1.1rem;
}

.footer .footer-display ul li a.active {
    /* color: var(--base-color) !important; */
    /* border-top: 3px solid var(--base-color); */
    color: green !important;
}

.footer .footer-display ul li a.active h3 {
    color: green !important;
    /* font-size: ; */
}

.footer .footer-display ul li a .top-border {
    height: 4px;
    width: 100%;
    background: var(--base-color);
    position: absolute;
    top: 0px;
    display: none;
}

.footer .footer-display ul li a.active .top-border {
    display: block;
}

.footer .footer-display ul li svg.smaller {
    /* height: 18px; */
}

.footer .footer-display .icon-cover {
    /* height: 20px; */
    width: 100%;
    margin-bottom: 0px;
}

.footer .footer-display .icon-cover ion-icon {
    font-size: 2rem;
    margin-bottom: 2px;
}


.content-display-content {
    /* height: calc(100% - 290px); */
    overflow: scroll;
}


.homepage .content-display-content {
    padding-bottom: 15%;
}

.promo-div img {
    width: 100%;
    height: auto;
}

.owl-dots,
.owl-nav {
    display: none !important;
}

/* history-data-display */
.history-data-display {
    background: var(--base-color);
    height: 30rem;
    padding-top: 10px;
}

.sec-page-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
    margin-top: 10px;
}

.small-page-title .sec-page-title {
    margin: unset;
    font-size: 1.6rem;
}

/* .history-data-detail {
    background: #fff;
    height: calc(100% - 30rem);
} */

.history-data-display .btn-white {
    margin-top: 20px;
}

.history-data-display .grid-flex {
    padding: 0% 1.5%;
    align-items: center;
    margin-bottom: 40px;
}

.history-data-display .grid-flex .icon-box svg {
    stroke: #fff;
}

.history-data-display .grid-flex h3 {
    color: #fff;
    font-size: 1.6rem;
    margin-bottom: 0px;
}

.history-data-detail .grid-2.history-disp {
    /* border-bottom: 2px solid #808080; */
    padding-top: 20px;
    text-align: center;
}

.history-data-detail .history-disp .history-header {
    padding: 10px;
}

.history-data-detail .history-disp .history-header h4 {
    font-size: 1.5rem;
}

.history-data-detail .history-disp.grid-2 {
    grid-gap: 0px;
}

.history-data-detail .history-disp .history-header {
    border-bottom: 2px solid #808080;
}

.history-data-detail .history-disp .active-history {
    border-bottom: 2px solid var(--base-color);
}

.history-data-detail .disp-category {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: #808080;
    background: #DDF7FF;
    background: rgba(13, 19, 215, .7);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.history-data-detail .disp-category {
    background: #D6E6FD;
}

.history-data-detail .disp-category ion-icon {
    color: #0D60D8 !important;
}

.history-data-detail .disp-category ion-icon {
    color: #0D60D8;
    color: #fff;
    font-size: 2rem;
    transform: rotate(-30deg);
}

.history-data-detail .disp-category ion-icon.down {
    transform: rotate(70deg);
}

/*  */

.history-data-detail .current-history-display .disp-category h3 {
    margin: 0px;
    /* color: #fff; */
}

.single-history .history-data-detail .current-history-display {
    display: flex;
    justify-content: space-between;
}

.single-history .history-data-detail .current-history-display h3 {
    margin-bottom: 0px;
}

.top-card-display p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-family: 'Gilroy Medium';
}

.top-card-display h3 span.numeric {
    font-size: 2.3rem;
    letter-spacing: 2.2px;
    padding-left: 5px;
}

.history-data-detail .current-history-display {
    display: grid;
    grid-template-columns: 1fr 3fr 1.5fr;
    align-items: center;
}

.history-data-detail .current-history-display.first-history-disp {
    margin-top: 20px;
}

.history-data-detail .current-history-display ul {
    margin-bottom: 0px;
    display: flex;
    grid-gap: 5px;
}

.history-data-detail .current-history-display p,
.history-data-detail .current-history-display ul li {
    list-style: none;
    font-size: 1.2rem;
    font-family: 'Gilroy Medium';
}

.history-data-detail .current-history-display p {
    text-align: right;
}

.history-data-detail .current-history-display h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    /* font-family: 'Gilroy Medium'; */
}

.history-data-detail .current-history-display h3.history-amount {
    font-family: 'Gilroy Bold';
    text-align: right;
}

h3.history-amount.red {
    color: #F03020;
}

h3.history-amount.green {
    color: #096D55;
}

.history-data-detail .current-history-display p.status {
    font-size: 1.2rem;
    font-family: 'Gilroy Medium';
    text-align: right;
    /* color: rgb(0, 0, 0); */
}

.history-data-detail .current-history-display p.status.success {
    color: #096D55;
}

.history-data-detail .current-history-display p.status.debit {
    color: #DB2544;
}


.page-display-control {
    background: #EAEDEC;
    height: 5rem;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.page-display-control .page-display-selector {
    height: 5rem;
    padding: 0px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.page-display-control .active-controller.page-display-selector {
    background: #fff;
    border-radius: 50px;
}

.page-display-block .grid-flex {
    align-items: center;
}

.page-display-block .grid-flex p {
    font-size: 1.5rem;
    line-height: 1.6;
    margin-bottom: 0px;
}


/* component */
.page-tab-list ul {
    display: flex;
    grid-gap: 0px;
}

.page-tab-list ul li {
    list-style: none;
    font-size: 1.4rem;
    position: relative;
    padding: 15px;
    padding-bottom: 10px;
    padding-top: 0px;
    color: var(--base-color);
}

.page-tab-list ul li.active-tab-list .bottom-border {
    border-bottom: .5rem solid var(--base-color);
    height: unset;
    width: 100%;
    position: absolute;
    bottom: -.5rem;
    left: 0px;
}


.small-page-title {
    grid-gap: 5px;
    position: fixed;
    height: 6rem;
    top: 0px;
    width: 100%;
    background: #fff;
    z-index: 10;
    border-bottom: 2px solid var(--gray-color);
    display: flex;
    align-items: center;
    padding: 0px 1.5%;
}

.small-page-title .grid-flex {
    width: 100%;
    align-items: center;
}

.small-page-title ion-icon {
    font-size: 4rem;
}

.restaurantBack ion-icon {
    font-size: 2.5rem;
}

.small-page-title .chat ion-icon {
    font-size: 2.5rem;
}

.signin-page .small-page-title ion-icon {
    font-size: 3rem;
    /* color: var(--green); */
    /* color: var(--base-color); */
}

.display-main-content {
    padding-top: 5rem;
}

.small-page-title svg {
    height: 2rem;
    width: auto;
}

.small-page-title h5 {
    font-size: 1.6em;
}

.large-page-title h5 {
    font-size: 2rem;
}

.medium-page-title h5 {
    font-size: 1.6rem;
}

.form-group-copy {
    border-bottom: 1px solid #F3F3F3;
    padding: 15px 0px;
}

.form-group-copy.grid-flex {
    align-items: center;
}

.form-group-copy.grid-flex h5 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-family: 'Gilroy Medium';
}

.form-group-copy.grid-flex p {
    font-size: 1.4rem;
    margin-bottom: 5px;
    font-family: 'Gilroy Medium';
}

.form-group-copy.grid-flex svg {
    height: 2rem;
}

.top-welcome-address h5 {
    font-size: 1.4rem;
}

.top-welcome-address p {
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.top-welcome-address svg {
    height: 2rem;
    width: auto;
}

.top-welcome-address.grid-flex {
    align-items: center;
}

.top-welcome-address.grid-flex {
    align-items: center;
}

.top-bar.contain.fix {}

.ant-skeleton.ant-skeleton-element {
    width: 100%;
}

.empty-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15rem;
    text-align: center;
}

.empty-data img {
    width: 15%;
    height: auto;
}

.single-box {
    background: #FDF2EE;
    border-radius: 12px;
    height: 10rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 0px 30px;
}

.single-box .blob-cover {
    position: absolute;
    bottom: -90px;
    right: -50px;
    bottom: -10px;
    right: -10px;
}

.single-box .blob-cover img {
    width: 100%;
    height: 100%;
    transform: rotate(-30deg);
}

.single-box.active {
    border: 4px solid #0D60D8;
}

.single-box h3 {
    margin-bottom: 5px;
    font-size: 1.4rem;
    color: #E0644D;
}

.single-box h3 span {
    font-size: 1.5rem;
}

.single-box p {
    font-size: 1.1rem;
    color: #F9C5A7;
}

.single-box._2 {
    background: #E2F8EC;
}

.single-box._2 h3 {
    color: #42AC86;
}

.box-display {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px; */
    display: flex;
    width: max-content;
    grid-gap: 5px;
    background: #ECE9EA;
    padding: 6px 8px;
    border-radius: 12px;
}


.summary-icon-bg {
    background: #FEF2DA;
    width: max-content;
    padding: 0px 0px 0px;
    margin-bottom: 10px;
    border-radius: 8px;
    background: none;
}

.summary-icon-bg ion-icon {
    font-size: 2.4rem;
    stroke: #F0715E;
    fill: #F0715E;
    display: none;
}

.summary-bg-block {
    background: #0E61D9;

    /* background: #20293A;
    border: 2px solid #213A69; */
    /* --light 214170 */
    padding: 10px 15px;
    border-radius: 12px;
}

.summary-bg-block h5 {
    color: #E4E4E4;
    margin-bottom: 10px;
    display: block;
    font-size: 1.1rem;
}

.summary-bg-block h3 {
    display: block;
    font-size: 1.6rem;
    color: #fff;
}

.summary-bg-block._2 {
    background: #7F1DE6;
    /* background: #2B3C36;
    border: 2px solid #2C744D; */
    /* --light 46745D */
}

.summary-bg-block._3 {
    background: #222222;
    /* background: #263039;
    border: 2px solid #466783; */
    /* --light - 305A7C */
}

.summary-bg-block._4 {
    background: #6A3ADE;
    /* background: #413330;
    border: 2px solid #9F4329; */
    /* --light 7C3F2A */
}

.summary-bg-block .grid-flex {
    align-items: end;
}

.summary-bg-block ion-icon {
    color: #E4E4E4;
    font-size: 1.8rem;
    line-height: 1;
}

.avatar {
    background: #C3B0A1;
    background: #237BF6;
    background: #1A1A1A;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar h3 {
    color: #fff;
}

.avatar-cover {
    display: flex;
    align-items: center;
    grid-gap: 5px;
}

.avatar-cover p {
    margin-bottom: 0px;
    font-size: 1.2rem;
}

.avatar-cover h4 {
    margin-bottom: 3px;
}

/* 0D60D8 */
/* -- 257BF4 */
.sect-box-tile {
    margin-bottom: 5px;
    font-size: 1.5rem;
}

/* antd drawer */
/* .ant-drawer-content-wrapper {
    bottom: unset !important;
    top: 0px !important;
} */

.ant-drawer-content-wrapper {
    height: auto !important;
    overflow: scroll;
}

.ant-tabs-tab-btn {
    font-family: 'Gilroy Medium';
}


.ant-drawer-content,
.ant-drawer-content-wrapper {
    border-radius: 20px 20px 0px 0px;
    /* border-radius: 64px 64px 0px 0px; */
}


/* drawer */
.ant-drawer .ant-drawer-header {
    padding: 16px 1.5%;
    display: none;
}

.ant-drawer .ant-drawer-body {
    padding: 24px 1.5%;
    padding: 12px 1.5%;
    /* height: 100vh !important; */
    /* padding-bottom: 24px; */
}

/* .ant-drawer-content-wrapper {
    height: 100vh !important;
} */

.ant-drawer .trans-code .ant-drawer-body {
    padding: 20px 0px;
}

.ant-drawer .trans-code .ant-drawer-body a {
    font-size: 1.3rem;
    font-family: 'Gilroy Medium';
}

.modal-cancel {
    height: 3rem;
    margin-right: 10px;
}

.main-center-message {
    display: flex;
    height: 90dvh;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.main-center-message p {
    font-size: 1.4rem;
    width: 80%;
    margin: 0px auto;
    display: block;
}

.main-center-message h4 {
    font-size: 1.6rem;
    margin-bottom: 10px;
}

.arg-disp {
    background: #003032;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}


.modal-disp.status-bar {
    padding-top: 5%;
}

.modal-disp.status-bar.empty .extra-detail p.form-explanation {
    width: 100%;
}


/* New redesign */
.rounded-button {
    border-radius: 3rem;
    padding: 10px 25px;
    background: #258635;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 500;
    outline: none;
    border: none;
}

.text_h3{
    /* font-size: 3.5rem; */
    color: rgba(0, 0, 0, 0.85);
    font-family: "Gilroy Bold";
  }
  

/* DealCards */
.slide_1 {
    background-image: url('../images/homepage/slide_2.png');
}

.slide_2 {
    background-image: url('../images/homepage/slide_3.png');
    padding: 3rem 6rem;
}

.slide_1,
.slide_2{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slide_1 {
    padding: 3rem 0rem 0rem 6rem;
}

.slider_content h1 {
    color: #fff;
    /* font-size: 4rem; */
    font-family: "Gilroy Bold";
    margin-bottom: 0px;
}

.home_side_image {
    max-height: 200px;
    margin-left: auto;
    display: block;
}

.home_side_image img {
    max-height: 235px;
}

.rice {
    display: block;
}

.grid_flex,
.grid-flex-bias {
    display: flex;
    justify-content: space-between;
}


/* MODALS */
.partnerModal .ant-modal-close,
.appModal .ant-modal-close,
.underConstructionModal .ant-modal-close{
  display: block !important;
  outline: none;
}

.partnerModal,
.underConstructionModal {
  z-index: 1;
}

.partnerModal.ant-drawer-content{
    background: #f5fff5;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    height: 400px;
}

.partnerModal .ant-modal-content{
    background: #f5fff5;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.partnerModal .ant-modal-title{
    background: #f5fff5;
}


.appModal.ant-drawer-content{
    background: #f5fff5;
    border-radius: 10px;
    height: 400px;
}

.appModal h2 {
    display: flex;
    align-items: center;
    gap: 5px;
}
.appModal p span {
    margin-right: 8px;
}

.numbered-list {
    list-style-type: decimal;
    padding-left: 20px;
  }
  
  .numbered-list li {
    margin-bottom: 10px;
  }
  

  .blob-cover1{
    position: absolute;
    right: -40px;
    bottom: -10px;
    width: 30%;
}
  
  .blob-cover-bottom1 {
    bottom: unset;
    top: -40px;
    right: 60px;
}

/* RESTAURANT PAGE */
.wrapperBg {
    background-image: url("../images/restaurant/wrapperBg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 90px;
    max-height: 90px;
    min-height: 90px;
    /*height: auto;*/
}
  
.headerBg {
    background-image: url("../images/homepage/restaurant_slide_1.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 250px;
    max-height: 250px;
}
  
.singlePageHeader{
    background-image: url("../images/restaurant/SP_header.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
  
}
  
.restaurantBanner {
    background-image: url("../images/restaurant/restaurantBanner.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 300px;
}
  
/*.singleRestaurant_img{*/
/*    !*background-image: url("../images/restaurant/restaurant_img.png");*!*/
/*    background-position: center;*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*    width: 100%;*/
/*    height: 300px;*/
/*    max-height: 300px;*/
/*    min-height: 300px;*/
/*    border-radius: 30px;*/
/*}*/

.restaurant_card {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6.5rem 3rem
}

.restaurant-card {
    padding: 0px !important;
    border: none;
    border-radius: 16px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.restaurant-card .ant-card-body{
    padding: 0;
}

.restaurant-card img {
    width: 100%;
    height: 200px;
    border-radius: 16px 16px 0 0;
}

.rest_card_body .ant-card-cover img {
    width: 100%;
    height: 100px;
    background-size: cover;
}

.restaurant-card h3 {
    font-weight: bold;
    margin: 16px 0 8px;
}

.restaurant-card p {
    margin: 0;
    color: #959595;
}

.restaurant-card .card-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 16px;
}

.rest_card_body .ant-card-body{
    padding: 1px ;
}

.rest_card_body.checkout_ .ant-card-body{
    width: 100%;
    /*display: flex;*/
    padding: 24px;
    /*align-items: center;*/
}

.align-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.restaurant_dealCard .ant-card-body{
    padding: 0;
}


.select_search .ant-select-selector,
.rest_searchDrawer .ant-select-selector{
    color: white !important;
    outline: none;
    border: none;
    border-color: transparent !important;
    padding: 0 !important;
    background-color: transparent !important;
}

/* Placeholder text color */
  .select_search .ant-select-selection-placeholder,
  .select_search .ant-select-arrow,
  .select_search .ant-select-item,
  .rest_searchDrawer .ant-select-arrow{
    color: white !important;
    font-weight: 700;
  }

  /* Dropdown menu background */
  .select_search .ant-select-dropdown {
    background-color: transparent;
  }


.rest_searchDrawer .ant-select-selector {
    background-color: forestgreen !important;
    padding: 10px !important;
    border-radius: 30px;
}



/* SinglePage */
.flip-container {
    perspective: 1000px; /* Creates a 3D space for the flip effect */
    width: 100%; /* Adjust width as needed */
    height: 100%;
  }
  
  .flippable-card {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s; /* Smooth transition for the flip */
  }
  
  .flip-container.flipped .flippable-card {
    transform: rotateY(180deg); /* Flip effect */
  }
  
  .card-front,
  .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Hides the back side when flipped */
  }
  
  .card-back {
    transform: rotateY(180deg); /* Start the back side rotated */
  }
  
  .checkoutDrawer_wrapper.ant-drawer-content{
          border-radius: 0 0 !important;
  }

  .checkoutDrawer_wrapper .ant-drawer-content-wrapper .ant-drawer-header {
    border: 0 !important;
    border-radius: 0 0 !important;
  }

/* .ant-drawer-content {*/
/*    border-radius: 0 0 !important;*/
/*}*/
.ant-drawer-content-wrapper {
    border-radius: 0 0 !important;
}
  
  .underConstructionModal .ant-modal-body{
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    font-family: 'Arial', sans-serif;
  }
  
  .cancel {
    display: block;
    /* margin-top: 10px; */
    text-align: center;
    /*width: 5%;*/
    height: 2.5rem;
    width: auto;
    border: none;
    background: transparent;
    outline: none;
}

.foodCard_checkbox.ant-checkbox-wrapper span{
    margin-bottom: 0 !important;
}

.custom-select .ant-select-selector {
    padding: 8px !important;
    height: auto !important;
    display: flex !important;
    align-items: center !important;
}

.custom-select .ant-select-arrow {
    top: 80% !important;
    transform: translateY(-50%) !important;
    margin-top: 0 !important;
}

.restaurantBack_width {
    width: 50%; /* Default for screens wider than 320px */
}

@media (max-width: 320px) {
    .restaurantBack_width {
        width: 80%; /* For screens 320px and below */
    }
}





@media screen and (max-width: 720px) {
    .slide_1,
    .slide_2,
    .slide_3 {
        height: 200px;
        border-radius: 10px;
        padding: 2rem 3rem;
    }

    .home_side_image {
        display: none;
    }
    .rice {
        display: none !important;
    }
    .grid_flex,
    .grid-flex-bias {
        display: block;
    }
    .restaurant_card {
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem 1rem
    }
}

@media (max-width: 767px) {
    .checkoutDrawer .ant-drawer-content-wrapper{
      width: 100% !important;
    }
    
  }
  
