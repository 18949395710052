.cart-box {
    background: var(--base-color);
    background: #EDEDED;
    background: #1A1A1A;
    display: flex;
    padding: 10px 20px;
    border-radius: 50px;
    align-items: center;
    grid-gap: 10px;
}

.cart-box svg {
    stroke: #fff;
    height: 1.6rem;
}

.top-welcome-address svg.menu-icon {
    height: 2.5rem;
    transform: rotate(-30deg);
}

.cart-box h4 {
    font-size: 1.4rem;
    color: #fff;
    margin-bottom: 0px;
}

.search-bar {
    display: grid;
    grid-template-columns: .5fr 4fr;
    background: #F3F3F3;
    grid-gap: 0px;
    border-radius: 50px;
    border: 2px solid green;
}

.search-bar-input {
    background: transparent;
    height: 4.5rem;
    width: 100%;
    border: none;
    border-radius: 50px;
    font-family: 'Gilroy Medium';
    font-size: 1.3rem;
}

.search-bar .search-icon-cover {
    background: transparent;
    box-shadow: none;
    height: 4.5rem;
    width: 4.5rem;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-bar .search-icon-cover svg {
    height: 1.8rem;
    stroke: green;
}

.tags-box ul {
    display: flex;
    grid-gap: 5px;
    overflow-x: auto;
    flex-wrap: wrap;
}

.tags-box .single-tag {
    background: #F3F3F3;
    border-radius: 50px;
    padding: 10px 0px;
    font-size: 1.2rem;
    list-style: none;
    text-align: center;
    font-family: 'Gilroy Medium';
    color: var(--base-color);
}

.top-bar.fixed {
    position: fixed;
    top: 0px;
    background: #fff;
    width: 100%;
    z-index: 10;
    height: max-content;
    padding-bottom: 10px;
    padding-top: 1.5%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, .2);
}

.wise9ja-disp .wise9ja-card-detail .top-bar.fixed {
    background: linear-gradient(109.6deg, rgb(61, 121, 176) 11.3%, rgb(35, 66, 164) 91.1%);
}

.wise9ja-content.wise .top-bar.fixed {
    background: #E4E4E4;
}

.wise9ja-disp .top-bar.fixed {
    background: #E4E4E4;
}

.top-bar.fixed.mt_1 {
    margin-top: 0px;
}


.product-detail .top-bar {
    position: fixed;
    top: 0px;
    background: transparent;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.product-detail .top-bar.fixed {
    background: #fff;
}

.shop-banner-div {
    height: 20rem;
    background: #1A1A1A;
    width: 100%;
    border-radius: 16px;
}

.shop-banner-div img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
}


.customer {
    width: auto;
    height: 4rem;
}

.bills form {
    padding-bottom: 15rem;
}