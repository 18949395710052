@tailwind base;
@tailwind components;
@tailwind utilities;

.profile-display {
    min-height: 100dvh;
    padding-bottom: 10%;
    width: 100%;
    background: #DCDCDC;
    background: #F7F8FA;
}

.profile-display {
    background: #fff;
}

.avatar-blk-cover {
    background: #111;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-blk-cover.center {
    margin: 0px auto;
    display: block;
}


/* .summary-main-link img {
    height: 4rem;
    width: auto;
    margin: 0px auto;
    display: block;
    margin-bottom: 10px;
} */

.summary-main-link {
    background: #F3F3F3;
    padding: 15px 15px;
    border-radius: 12px;
    display: block;
    /* padding-bottom: 0px; */
}

.summary-main-link h5 {
    font-size: 1.3rem;
    margin: 0px;
}

.summary-main-link h3 {
    font-size: 1.6rem;
    margin-bottom: 5px;
}

.gray-banner {
    background: #F3F3F3;
    height: 8rem;
    width: 100%;
    border-radius: 12px;
}

.profile-display.settings-display .settings-list ul li {
    font-size: 1.3rem;
    /* line-height: 3.6; */
}

.profile-display.settings-display .settings-list ul li svg {
    height: 1.3rem;
}

.profile-display.settings-display .settings-list ul li svg.extra {
    height: 1.6rem;
}

.profile-display.settings-display .settings-list ul li a {
    text-decoration: none;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding: 18px 10px;
    justify-content: space-between;
}

.profile-display.settings-display .settings-inner-list {
    background: #fff;
    margin-top: 20px;
}

.profile-display.settings-display .settings-list ul li a ion-icon {
    font-size: 1.8rem;
}

.profile-display.settings-display .settings-list .logo-box img {
    height: auto;
    width: 30%;
}

.profile-display.settings-display .settings-list .logo-box {
    position: absolute;
    bottom: 20px;
    width: 100%;
    width: 100%;
    text-align: center;
}

.divider-line {
    height: .5rem;
    width: 100%;
    background: #F3F3F3;
}

.settings-detail-summary .grid-flex {
    align-items: center;
    padding: 10px 0px;
}

.settings-detail-summary .grid-flex p {
    font-size: 1.2rem;
    margin-bottom: 0px;
    line-height: 1.8;
}

.settings-detail-summary .grid-flex h4 {
    font-size: 1.7rem;
    margin-bottom: 5px;
}

.content-summary-box {
    border: 1px solid #0E61D9;
    padding: 15px 15px;
    background: #0E61D9;
    border-radius: 4px;
}

.content-summary-box._2 {
    background: #7F1DE6;
    border: 1px solid #7F1DE6;
}

.content-summary-box._3 {
    background: #222222;
    border: 1px solid #222222;
}

.content-summary-box._4 {
    background: #6A3ADE;
    border: 1px solid #6A3ADE;
}

.content-summary-box h4 {
    font-size: 1.8rem;
    color: #fff;
    margin-bottom: 5px;
}

.content-summary-box p {
    margin-bottom: 0px;
    color: rgb(255, 255, 255, .7);
    font-size: 1.2rem;
    font-family: 'Gilroy Medium';
}

.wise9ja-disp .wise9ja-card-detail .content-summary-box p {
    text-align: left;
}

.confirmation_page {
    background: #3B16A7;
    display: flex;
    align-items: center;
    height: 100dvh;
    width: 100%;
}

.confirmation_page.center {
    justify-content: center;
}

.confirmation_page .receipt-display {
    width: 90%;
    margin: 0px auto;
    margin-top: -30%;
}

.confirmation_page .display-main-content {
    background: #fff;
    width: 100%;
    padding: 20px 0px 40px;
    border-radius: 12px;
}

.confirmation_page .display-main-content .panel-divider-block {
    height: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.confirmation_page .display-main-content .small-circle {
    width: 20px;
    height: 20px;
    background: #3B16A7;
    border-radius: 50%;
    position: absolute;
    top: 0px;
}

.confirmation_page .display-main-content img {
    width: 30%;
    height: auto;
    margin-bottom: 10px;
}

.confirmation_page .display-main-content .status-header {
    text-align: center;
}

.confirmation_page .display-main-content .status-header h3 {
    font-size: 1.8rem;
}

.confirmation_page .display-main-content .small-circle.left {
    left: -10px;
}

.confirmation_page .display-main-content .small-circle.right {
    right: -10px;
}

.top-action-cover {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-action-cover .icon-cover {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 2px solid var(--gray-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.body-text {
    font-size: 1.3rem;
    line-height: 1.8;
}

.success-text {
    color: green;
    font-family: 'Gilroy Bold';
}

.modal-input-box {
    /* display: flex;
    grid-gap: 10px; */
}

.modal-input-box input {
    width: 100% !important;
    height: 5.5rem;
}

.otp-input-cover {
    width: 80%;
    width: 90%;
    margin: 0px auto;
    display: block;
}

.otp-input-box .otp-input-cover {
    width: 100%;
}

.otp-cover .otp-input-cover {
    width: 95%;
}

.otp-cover .modal-input-box .otp-input-cover div {
    grid-gap: 5px;
}

.modal-input-box div {
    grid-gap: .5px;
}

.onscreen .hg-theme-default .hg-button {
    background: transparent;
    box-shadow: none !important;
    /* border-bottom: 2px solid #222222; */
    border: none;
    border-radius: 0px;
    font-family: 'Gilroy Bold';
}

.onscreen .hg-theme-default {
    /* background: transparent; */
    padding: 5px;
    border-radius: 0px;
}

.hg-theme-default.hg-layout-numeric .hg-button {
    background: #fff;
    border-radius: 5px;
}

.numeric-cover {
    background: #ececec;
    width: 100%;
    text-align: center;
    padding: 10px 0px 5px;
}

.modal-input-box .otp-input-cover div {
    grid-gap: 15px;
}

.numeric-cover a {
    font-size: 1.2rem;
    margin-bottom: 0px;
    color: #222222;
}

.center {
    text-align: center;
}

.confirmation_page .receipt-display .top-action-cover svg {
    width: 30%;
    stroke: #fff;
}

.confirmation_page .welcome-buttons a {
    padding: 15px 35px;
}

.flex-list li {
    display: flex;
    justify-content: space-between;
}

.flex-list li span {
    font-size: 1.3rem;
    line-height: 2.8;
}

.flex-list li span:nth-child(2) {
    font-family: 'Gilroy Bold';
}

.receipt-divider {
    width: 100%;
    border: 2px dotted var(--gray-color);
}

.content-grid-cover.grid-2 {
    grid-gap: 5px;
    row-gap: 10px;
}

.wise9ja-disp .wise9ja-card-detail .main-amount-box {
    margin-bottom: 10px;
}

.wise9ja-disp .wise9ja-card-detail .main-amount-box p {
    text-align: left;
    font-size: 1.4rem;
}

.wise9ja-disp .wise9ja-card-detail .main-amount-box h3 {
    text-align: left;
    font-size: 3rem;
}

.top-history-header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
}

.top-history-header .history-box {
    border-bottom: 2px solid #F3F3F3;
    padding-bottom: 10px;
}

.top-history-header .history-box.active-history {
    border-bottom: 2px solid var(--base-color);
}

.profile-first-sect {
    /* background: linear-gradient(109.6deg, rgb(61, 121, 176) 11.3%, rgb(35, 66, 164) 91.1%); */
    background: #f3f3f366;
    height: 60%;
    display: flex;
    align-items: end;
    justify-content: center;
    text-align: center;
    padding-bottom: 20px;
    position: relative;
}

.profile-first-sect .avatar-blk-cover {
    margin: 0px auto;
    display: flex;
    margin-bottom: 20px;
    border: 2px solid #2342A4;
    background: transparent;
    /* height: 100px;
    width: 100px; */
}

.profile-first-sect .avatar-blk-cover ion-icon {
    font-size: 4rem;
    color: #fff;
}

.avatar-blk-cover svg {
    width: 30%;
    height: 30%;
}


.top-body-box {
    position: absolute;
    top: 10px;
    left: 0px;
    width: 100%;
    padding: 0px 10px;
    z-index: 2;
}

.profile-first-sect button.btn-blank {
    color: #000;
    font-family: 'Gilroy Medium';
    margin-top: 5px;
}

.top-body-box .notification {
    width: 6%;
    height: 6%;
}

.top-body-box .logout {
    width: 5%;
    height: 5%;
}

.top-body-box .notification svg {
    width: 100%;
    height: 100%;
}

.settings-detail-summary {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.profile-display.settings-display .settings-list ul {
    border-top: 1px solid #DCDCDC;
}

.profile-display.settings-display .settings-list ul li {
    border-bottom: 1px solid #DCDCDC;
    /* line-height: 4.2; */
}

.profile-display.settings-display .settings-avatar-box {
    display: flex;
    padding: 15px 10px;
}

.profile-display.settings-display .settings-avatar-box .flex-div {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.profile-display.settings-display .settings-avatar-box .flex-div .avatar-cover {
    height: 80px;
    width: 80px;
}

.profile-display.settings-display .settings-list-display {
    background: rgba(243, 243, 243, .4);
    height: 100%;
}

.profile-first-sect h4 {
    text-transform: uppercase;
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #fff;
}
.profile-first-sect .text_black{
    text-transform: uppercase;
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #000;
}

.profile-first-sect p {
    font-size: 1.4rem;
    font-family: 'Gilroy Medium';
    color: #fff;
}

.settings-detail-summary .sect-message .grid-flex p {
    font-size: 1.3rem;
    font-family: 'Gilroy Medium';
}

.sect-message {
    border-bottom: 2px solid #F3F3F3;
}

.sect-message button {
    padding: 8px 25px;
    font-size: 1.4rem;
}

.extra-detail ul li {
    line-height: 4.5;
    border-bottom: 2px solid #DCDCDC;
}

.extra-detail ul li:last-child {
    border-bottom: none;
}

.extra-detail ul li span {
    font-family: 'Gilroy Medium';
    font-size: 1.3rem;
}

.main-otp-display div input {
    height: 6rem;
    width: 100% !important;
}

.main-otp-display div {
    width: 100%;
    grid-gap: 10px;
}

.amount-banner {
    height: 20rem;
    background: var(--green);
    background: #0D60D8;
    width: 100%;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
}

.blob-cover {
    position: absolute;
    right: -40px;
    bottom: -10px;
    width: 30%;
}

.blob-cover._2 {
    bottom: unset;
    top: -40px;
    right: 60px;
}

.blob-cover img {
    width: 100%;
    height: 100%;
}

.amount-banner-div {
    position: absolute;
    bottom: 10px;
    width: calc(100% - 20px);
    left: 10px;
}

.amount-banner-div .black-bg {
    background: #111;
    border: 1px solid #111;
    padding: 5px 15px;
    width: max-content;
    /* margin-bottom: 30px; */
    display: block;
    color: #fff;
    font-size: 1.1rem;
    border-radius: 50px;
}

.amount-banner-div .amount-banner-list {
    background: #2B74DD;
    padding: 10px 10px;
    border-radius: 8px;
}

.amount-banner .amount-banner-div .grid-2 {
    width: max-content;
}

.amount-banner-div .amount-banner-list p {
    font-size: 1rem;
    color: #D6E6FD;
    margin-bottom: 5px;
    font-family: 'Gilroy Medium';
}

.amount-banner .amount-main-bar {
    margin-bottom: 20px;
}

.amount-banner .amount-main-bar h3 {
    font-size: 2.8rem;
    margin-bottom: 0px;
    color: #fff;
}

.amount-banner .amount-main-bar p {
    font-family: 'Gilroy Medium';
    font-size: 1.3rem;
    margin-bottom: 5px;
    color: #D6E6FD;
}
.font-gil{
    font-family: 'Gilroy Medium';
    font-size: 1.3rem;
}

.amount-banner-div .amount-banner-list h5 {
    font-size: 1.4rem;
    color: #fff;
}

.wise9ja-disp .amount-banner .grid-flex {
    margin-bottom: 25px;
    position: relative;
    z-index: 5;
}

.wise9ja-disp .amount-banner .grid-flex a {
    color: #fff;
}

.quick-action {
    text-align: center;
}

.quick-action .grid-4 {
    grid-gap: 35px;
}

.quick-action .card-action-box p {
    font-size: 1.2rem;
}

.quick-action .card-action-box .wise9ja-icon-cover {
    background: #5ab170d7;
}

.quick-action .action-button-icon ion-icon {
    font-size: 3rem;
}

.quick-action .card-action-box .wise9ja-icon-cover svg {
    stroke: #fff;
}

.homepage.wise9ja-disp .contain {
    padding: 0px 3%;
}

.sec-story-banner {
    background: #F0F4FD;
    width: 100%;
    height: 8rem;
    background: #D6E6FD;
    border-radius: 12px;
}

.gray-bg-cover {
    background: #F8F8F8;
}

.wise9ja-content {
    background: #F8F8F8;
    min-height: 100dvh;
    height: 100%;
}

.homepage-history-header {
    background: #fff;
    padding: 40px 0px 20px;
}

.outside-link {
    font-size: 1.4rem;
}

.checkoutDrawer_wrapper.ant-drawer-content{
    border-radius: 0 0 !important;
}

.checkoutDrawer_wrapper .ant-drawer-content-wrapper .ant-drawer-header {
    border: 0 !important;
    border-radius: 0 0 !important;
}

/* .ant-drawer-content {*/
/*    border-radius: 0 0 !important;*/
/*}*/
.ant-drawer-content-wrapper {
    border-radius: 0 0 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;