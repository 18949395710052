@tailwind base;
@tailwind components;
@tailwind utilities;

.signin-page {
    height: 100dvh;
    overflow: scroll;
}

.auth-banner {
    background: url('../images/illustrations/auth_1.jpg');
    background-size: cover;
    background-position: center;
    height: 30rem;
    height: 30dvh;
}

/* .signin-page .auth-content {
    height: calc(100% - 30dvh);
} */

.form-flex-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

/* label {
    font-size: 1.3rem;
    font-family: 'Gilroy Medium';
} */

input,
textarea,
.ant-select-selector,
.ant-input-password {
    border-radius: 4px ;
    border: 1px solid #e1e1e5 ;
    font-size: 1.2rem ;
    background: #ededf5 ;
    background: transparent ;
    font-family: 'Gilroy Medium' ;
    font-size: 1.2rem !important;
}

input[type="password"],
.ant-input-password input,
.ant-input.search-bar-input,
.ant-select-selector input {
    border: none !important;
}

.otp-inner-div input[type="password"] {
    border: 1px solid #e1e1e5 !important;
}

label {
    font-size: 1.3rem;
    font-family: 'Gilroy Medium';
    margin-bottom: 5px;
    display: block;
}

/* form */
.form_group {
    margin-bottom: 20px;
    display: block;
    width: 100%;
}

.form_group.button_div {
    margin-bottom: 10px;
}

.ant-drawer-title {
    font-family: 'Gilroy Bold';
}

/* .signin-page form button {
    width: 100%;
    display: block;
    padding: 13px 25px;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.8;
    color: #fff;
    border-radius: 4px;
} */

.signin-page form button.auth-btn-blank {
    background: transparent;
    border: 1px solid #808080;
    color: var(--base-color);
}

.form-error {
    font-size: 1.2rem;
    font-family: 'Gilroy Medium';
    margin-top: 10px;
    display: block;
    color: #B60001;
}

.signin-page .contain.auth-content .error-block p.form-error {
    margin-bottom: 20px;
    font-size: 1.3rem;
    display: block;
}

.signin-page a.inner-link {
    font-size: 1.4rem;
    line-height: 1.8;
    text-decoration: none;
    /* font-family: 'Gilroy Medium'; */
    text-decoration: underline;
    color: green;
    /* color: var(--base-color); */
}

.signin-page .extra-links {
    margin-top: 0px;
}

.ant-checkbox-wrapper span {
    font-weight: normal;
    display: block;
    margin-bottom: 10px;
    font-family: 'Gilroy Medium';
}

.signin-page .ant-checkbox-wrapper span {
    color: #F03020;
    font-family: 'Gilroy Bold';
}

.ant-checkbox-wrapper .ant-checkbox-inner {
    margin-bottom: 0px;
}

.vendor-signup-div {
    height: auto;
    max-height: max-content;
    overflow: hidden;
    transition: max-height .5s ease;
    max-height: calc(10rem + 80px);
}

.ant-checkbox .ant-checkbox-inner {
    border-color: #d9d9d9 !important;
}

.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
}

.signin-page .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background: #F03020 !important;
    border-color: #d9d9d9 !important;
}

.vendor-signup-div.hide {
    max-height: 0px;
}

.signin-page .extra-links a {
    display: block;
    margin-bottom: 20px;
}

.signin-page h3 {
    font-size: 2rem;
    margin-bottom: 5px;
    padding-top: 20px;
}

.signin-page h3.auth-sect-title {
    display: block;
    text-align: center;
}

.signin-page .auth-sect-div {
    text-align: center;
    margin-bottom: 40px;
}

.signin-page .auth-sect-div img {
    width: 30%;
    height: auto;
}

.signin-page .auth-sect-div p {
    font-size: 1.4rem;
    font-family: 'Gilroy Medium';
    width: 100%;
    margin: 0px auto;
    display: block;
    line-height: 1.4;
}

.signin-page .auth-sect-div p.width-85 {
    width: 85%;
}

.signin-page.verification_page h3.auth-sect-title {
    margin-bottom: 5px;
}

.joinus h3.auth-sect-title {
    font-size: 1.6rem;
    margin-bottom: 30px;
}

.auth-content.contain {
    padding: 0px 3%;
}

.signin-page.verification_page .contain.auth-content p {
    margin-bottom: 0px;
    font-size: 1.4rem;
    line-height: 1.8;
    margin-bottom: 40px;
}

.contain.auth-content .mt_3 {
    margin-top: 6.5rem;
}

/* .signin-page p {
    font-size: 1.3rem;
    margin-bottom: 20px;
    line-height: 1.6;
    font-family: 'Gilroy Medium';
} */

.signin-page .contain.auth-content p {
    margin-bottom: 10px;
}

.status-box {
    background: #000;
    height: 40rem;
    border-radius: 24px;
    margin-bottom: 30px;
}

.status-page p {
    font-size: 1.6rem;
    line-height: 1.6;
    margin-bottom: 10px;
}

.status-page h3 {
    font-size: 1.8rem;
    line-height: 1.6;
    margin-bottom: 10px;
}

.status-page a {
    font-size: 1.6rem;
    line-height: 1.6;
}

.ant-radio-group.ant-radio-group-solid {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
}

.kyc .ant-radio-group.ant-radio-group-solid {
    grid-gap: 10px;
}

.ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.otp-input-box {
    display: flex;
    grid-gap: 10px;
    /* display: grid;
    grid-template-columns: repeat(5, 1fr); */
}

.otp-input-box input {
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}


/*  */
.signin-page {
    height: 100%;
    height: 100dvh;
    overflow: scroll;
    background: #F7F7F9;
    background: #fff;
    position: relative;
}

.auth-banner {
    background: url('../images/illustrations/auth_1.jpg');
    background-size: cover;
    background-position: center;
    height: 30rem;
    height: 30dvh;
}

.form-page {
    background: #F7F7F9;
}

.form-page .white-bg {
    border-top: 1px solid #E1E1E5;
}

.form-page .top-bar {
    background: #fff;
}

.form-page .white-bg input,
.form-page .white-bg textarea,
.form-page .white-bg .ant-select-selector,
.form-page .white-bg .ant-input-affix-wrapper,
.form-page .white-bg .ant-picker {
    background: #EDEDF5;
    border: 2px solid #E1E1E5 !important;
    border-radius: 12px !important;
}

.form-page .white-bg .ant-input-affix-wrapper input,
.form-page .white-bg .ant-picker .ant-picker-input,
.form-page .white-bg .ant-picker .ant-picker-input input {
    border: none !important;
}

.form-page .ant-radio-button-wrapper {
    background: #fff !important;
    border: 1px solid #E1E1E5 !important;
    margin-bottom: 0px !important;
    border-radius: 4px !important;
}

.form-page .ant-radio-button-wrapper:nth-child(1) {
    border-radius: 4px 4px 4px 4px !important;
}

.form-page .ant-radio-button-wrapper:nth-child(2) {
    border-radius: 4px 4px 4px 4px !important;
}

.form-page .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: var(--green-shade) !important;
    border-color: var(--green-shade) !important;
    color: #000 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: var(--green-shade) !important;
}

.ant-picker {
    border: 1px solid #e1e1e5 !important;
}

.ant-picker-input input {
    height: 5rem !important;
    border: none !important;
}

.form-page .white-bg label {
    margin-bottom: 5px;
    display: block;
}



.modal-title {
    margin-bottom: 20px;
}

.modal-disp label {
    font-size: 1.3rem;
}

.modal-disp input {
    border: 2px solid #D9D9D9;
}


.date-box {
    display: flex;
    align-items: center;
    text-align: center;
    grid-gap: 10px;
    flex-wrap: wrap;
}

.wise9ja-earnings-box .date-box li {
    background: #E8E8E8;
    padding: 6px 15px;
    border: 2px solid #E8E8E8;
    list-style: none;
    border-radius: 4px;
    margin-bottom: 0px;
    font-size: 1.2rem;
}

.wise9ja-earnings-box ul li {
    line-height: 1.8;
    margin-bottom: 20px;
    list-style: inside;
    font-size: 1.3rem;
    font-family: 'Gilroy Medium';
}

.ant-divider-inner-text {
    font-family: 'Gilroy Bold';
    font-size: 1.5rem;
}

.center-content {
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-content.short {
    height: max-content;
}

.center-content .center-content-text {
    width: 80%;
    margin: 0px auto;
    display: block;
    text-align: center;
}

.center-content .center-content-text a {
    display: block;
    padding: 12px;
    width: 100%;
    margin: 0px auto;
    display: block;
    text-decoration: none;
    font-size: 1.3rem;
}

.center-content .center-content-text p {
    font-size: 1.3rem;
    display: block;
    line-height: 1.8;
    margin-bottom: 15px;
    font-family: 'Gilroy Medium';
}

.center-content .center-content-text h4 {
    font-size: 1.8rem;
    display: block;
    line-height: 1.8;
    margin-bottom: 0px;
}

.center-content .center-content-text img {
    height: 8rem;
    width: auto;
}

.e-id-box p {
    font-size: 1.4rem;
    line-height: 2.8;
}

.e-id-box h5 {
    font-size: 1.8rem;
}

.e-id-box .form-content {
    margin-bottom: 0px;
}

.e-id-box .avatar-blk-cover {
    margin: 0px auto;
    display: flex;
    width: 120px;
    height: 120px;
    margin-bottom: 40px;
}

.profile-first-sect .avatar-blk-cover {
    height: 90px;
    width: 90px;
    overflow: hidden !important;
}

.eid .profile-first-sect .avatar-blk-cover {
    height: 130px;
    width: 130px;
    overflow: hidden;
    border-radius: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.avatar-blk-cover img {
    width: 100%;
    height: 100%;
    /* height: 20px; */
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.eid .avatar-blk-cover img {
    border-radius: 4px;
    height: 120px;
    width: 120px;
}

.eid .top-body-box .grid-flex svg {
    stroke: #fff;
}

.eid .cart-content.display-main-content {
    padding-top: 3rem;
}

.e-id-box .grid-2 {
    grid-gap: 30px;
    row-gap: 10px;
}

.e-id-box .qrcode {
    width: 40%;
    height: auto;
    margin: 0px auto;
    display: block;
    margin-top: 60px;
}



.story-page {
    background: #E4E4E4;
    min-height: 100dvh;
}

.story-page .white-block {
    background: #fff;
    padding: 20px 3%;
    border-radius: 6px;
}

.story-page p {
    font-size: 1.4rem;
    line-height: 1.8;
}

.story-page p.timer {
    color: #B60001;
    font-family: 'Gilroy Medium';
}

.story-page form p {
    font-size: 1.2rem;
}

.story-page form .form_group.sec {
    margin-bottom: 0px;
    border: red;
}

.story-page label {
    margin-bottom: 5px;
    display: block;
}

.story-page .white-block h4 {
    margin-bottom: 20px;
    font-size: 1.4rem;
    line-height: 1.6;
}

.story-page .white-block h4.sec-title {
    margin-bottom: 10px;
    margin-top: 20px;
}

.story-page .white-block h4.quiz-section-title {
    margin-bottom: 5px;
}

.story-page .white-block p.quiz-section-story {
    margin-bottom: 20px;
}

.story-page .white-block h4.quiz-question {
    margin-bottom: 0px;
}

.story-page.how-to h3 {
    text-align: left;
}


.tagbox {
    display: flex;
    flex-wrap: wrap;
}

.tagbox p {
    font-size: 1.3rem !important;
    line-height: 1.6;
    margin-bottom: 0px !important;
}

.tagbox .tagbox-div {
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 50px;
    border: 1px solid #212529;
    padding: 6px 25px;
}


.quiz-options .ant-radio-group {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    grid-gap: 30px;
    row-gap: 10px;
}

.quiz-options .ant-radio-group .ant-radio-wrapper {
    border: 2px solid #F0F0F0;
    font-family: 'Gilroy Medium';
    padding: 6px 15px;
    border-radius: 50px;
    width: 100%;
}

.result-tab {
    display: flex;
    flex-direction: column;
}

.result-tab h4 {
    text-align: center;
}

.result-tab button {
    width: 50%;
    margin: 0px auto;
    display: block;
}

.result-tab a {
    text-align: center;
}

.story-page .white-block .result-tab h4 {
    margin-bottom: 20px;
    font-size: 5rem;
}


.wallet-bg {
    height: 15rem;
    background: #0D60D8;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
}

.wallet-bg.red {
    background: #F2784E;
}

.wallet-bg.green {
    background: #6EBB91;
}

.price-bar {
    position: absolute;
    bottom: 10px;
    left: 20px;
    width: 100%;
}

.wise9ja-disp .wise9ja-card-detail .price-bar p {
    color: rgb(228, 228, 228);
    text-align: left;
    margin-bottom: 10px;
}

.wise9ja-disp .wise9ja-card-detail .price-bar h3 {
    color: #fff;
    font-size: 2.8rem;
    margin-bottom: 0px;
}

.wise9ja-disp .wise9ja-card-detail .blob-cover {
    position: absolute;
    right: -25px;
    bottom: -30px;
}

.wise9ja-disp .wise9ja-card-detail img {
    height: 10rem;
    width: auto;
}

.wise9ja-disp .wise9ja-card-detail .btn-yellow {
    background: #F2B83A;
    padding: 8px 15px 5px;
    font-size: 1.2rem;
    border-radius: 50px;
    width: max-content;
    font-family: 'Gilroy Bold';
    display: flex;
    align-items: center;
    grid-gap: 5px;
}

.wise9ja-disp .wise9ja-card-detail .wallet-bg.green .btn-yellow,
.wise9ja-disp .wise9ja-card-detail .wallet-bg.red .btn-yellow {
    background: #fff;
}

.wise9ja-disp .wise9ja-card-detail .btn-yellow ion-icon {
    font-size: 2rem;
}

.wise9ja-disp .wise9ja-card-detail .btn-yellow p {
    line-height: 1;
    margin-bottom: 0px;
}

.wise9ja-disp .wise9ja-card-detail .tag {
    background: #EDEDED;
    background: #E4E4E4;
    padding: 10px 15px 5px;
    font-size: 1.2rem;
    border-radius: 50px;
    width: max-content;
}

.wise9ja-disp .wise9ja-card-detail .wallet-bg .grid-flex {
    padding: 8px 10px;
}


.bottom-fold-info {
    position: absolute;
    bottom: 30px;
    text-align: center;
    font-size: 1.4rem;
    font-family: 'Gilroy Medium';
    width: 100%;
    left: 0px;
}

.curr-balance {
    border: 2px solid #808080;
    text-align: center;
    font-family: 'Gilroy Medium';
    padding: 12px 10px;
    border-radius: 50px;
    font-size: 1.3rem;
    /* transition: padding .2s ease-in-out; */
}

.active-nav {
    border: 2px solid var(--green);
    border-radius: 50px;
}

.active-nav .curr-balance {
    padding: 10px;
}

.history .swiper-slide {
    width: max-content !important;
}

.modal-input-box .otp-inner-div {
    display: flex;
    grid-gap: 20px;
    width: 90%;
    margin: 0px auto;
}

.modal-input-box .otp-inner-div input {
    text-align: center;
}


.inline-status-box .status-circle {
    background: #D6E6FD;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0px auto;
    display: block;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inline-status-box .status-circle img {
    width: auto !important;
    height: 5rem !important;
}

.inline-status-box .status-circle ion-icon {
    font-size: 2.3rem;
    color: #0D60D8;
}

.inline-status-box h4 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.inline-status-box p {
    font-size: 1.3rem;
    margin-bottom: 20px;
}

.inline-status-box a {
    display: block;
    text-decoration: none;
}

.inline-payment-option {
    background: #D6E6FD;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    border-radius: 50px;
    padding: 7px 10px;
    grid-gap: 10px;
}

.inline-payment-option .inline-option {
    padding: 15px 25px;
    border-radius: 50px;
    text-align: center;
}

.inline-payment-option .inline-option.active {
    background: var(--base-color);
    color: #fff;
}

.inline-payment-option .inline-option h4 {
    text-align: center;
    display: block;
    font-size: 1.4rem;
}

.inline-payment-option .inline-option.active h4 {
    color: #fff;
}

.message-box {
    background: #F8F8F8;
    padding: 15px;
    font-size: 1.3rem;
    line-height: 1.6;
    border-radius: 4px;
}

.message-box ion-icon {
    font-size: 1.6rem;
    line-height: .4;
}

.bg-white .flex-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delivery .bg-white ul li {
    list-style: none;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--gray-color);
    padding-top: 10px;
}

.delivery .bg-white ul li:first-child {
    padding-top: 0px;
}

.bg-white ul li h4 {
    font-size: 1.3rem;
    line-height: 1.5;
}

.welcome-display img.signup_img {
    width: 90%;
    margin: 0px auto;
    display: block;
    margin-bottom: 20px;
}

.ant-dropdown-menu-item {
    line-height: 2.6 !important;
}

.form-drawer-title {
    font-size: 1.6rem;
}

.form-drawer-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cancel-bar {
    background: #F2F2F2;
    height: 40px;
    width: 40px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cancel-bar ion-icon {
    font-size: 2rem;
}

.form-list-modal .ant-drawer-body {
    padding: 20px 20px !important;
}

.long-display {
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-tel-input .form-control {
    height: 5rem !important;
    width: 100% !important;
}



@tailwind base;
@tailwind components;
@tailwind utilities;