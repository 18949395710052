/* cart */
@tailwind base;
@tailwind components;
@tailwind utilities;

.cart-to-checkout {
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 10px 0px;
    border-top: 2px solid var(--gray-color);
    left: 0px;
    background: #fff;
    z-index: 10;
}

.cart-to-checkout button {}

.cart-to-checkout .cart-button-showcase {
    padding: 10px 1.5%;
}

.cart-product-bar {
    border-top: 2px solid var(--gray-color);
}

.cart-page {
    background: #FFFFFF;
    min-height: 100vh;
}

.cart-page ul li {
    line-height: 2.2;
}

.cart-image-showcase {
    display: grid;
    grid-template-columns: 1fr 3fr 1.5fr;
    grid-template-columns: 1fr 4.5fr;
    border-radius: 8px;
    align-items: center;
    grid-gap: 10px;
    margin-bottom: 0px;
    padding: 20px 1.5%;
    border-bottom: 2px solid var(--gray-color);
    border-radius: 0px;
}

.cart-image-showcase .grid-flex {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
}

.cart-image-showcase .cart-amount-detail {
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
}

.trash-bar svg {
    height: 1.6rem;
    margin-top: 10px;
}

.cart-image-showcase .grid-flex {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
    align-items: end;
}

.cart-image-showcase .trash-icon {
    height: 1.8rem;
}

.cart-image-showcase .cart-quantity-control {
    display: grid;
    grid-template-columns: .5fr 3fr .5fr;
    grid-gap: 10px;
    width: 100%;
    background: var(--gray-color);
    padding: 10px;
    border-radius: 50px;
}

.cart-image-showcase .cart-quantity-control .cart-quantity-control-text {
    height: 100%;
    width: 100%;
}

.cart-image-showcase .cart-quantity-control svg {
    height: 1.6rem;
}

.cart-image-showcase .cart-quantity-control h5 {
    font-size: 1.4rem;
    line-height: .8;
    text-align: center;
}

.cart-image-showcase .cart-quantity-control .cart-control-inner-box,
.cart-control-inner-box {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
}

.cart-text-box {
    display: block;
    width: 100%;
    height: 100%;
}

.cart-image-showcase h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.cart-image-showcase p {
    font-size: 1.3rem;
    margin-bottom: 10px;
}

.cart-image-showcase p.cart-item-price {
    font-size: 1.5rem;
    margin-bottom: 0px;
    font-family: 'Gilroy Medium';
}

.cart-image-showcase p.cart-item-price span {
    font-size: 1.2rem;
    margin-bottom: 0px;
}

.cart-image-showcase img {
    width: auto;
    height: 6rem;
    margin: 0px auto;
    display: block;
}


.discover-category-box {
    background: #A8411C;
    position: relative;
    height: 10rem;
    border-radius: 16px;
    padding: 20px;
}

.discover-category-box .discover-cat-img {
    position: absolute;
    bottom: -10px;
    right: 0px;
}

.discover-category-box .discover-cat-img img {
    height: 8rem;
    width: auto;
}

.discover-category-box p {
    font-size: 1.2rem;
    color: #fff;
    font-family: 'Gilroy Medium';
}

.discover-category-box h4 {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 5px;
}


/* product-image */
.product-image img {
    width: auto;
    height: 15rem;
    display: block;
}

.product-image {
    margin-bottom: 30px;
    background: #F3F3F3;
    height: 35rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-name-detail .fav-img svg {
    width: auto;
    height: 15px;
    stroke: var(--base-color);
}

.product-name-detail .fav-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid var(--base-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-name-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.product-name-detail p.unimportant {
    margin-top: 10px;
}

.product-name-detail.pricing p {
    font-size: 1.4rem;
}

.product-name-detail.pricing ion-icon {
    font-size: 1.6rem;
}

.product-name-detail h3 {
    font-size: 2rem;
}

.product-name-detail p.unimportant {
    font-size: 1.4rem;
}

.product-name-detail .detail-icon-cover {
    background: #6E1346;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-name-detail .detail-icon-cover ion-icon {
    color: #B04F88;
    font-size: 2rem;
}

.product-name-detail .detail-cover {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

.product-name-detail .detail-icon-cover._2 {
    background: #A8401E;
}

.product-name-detail .detail-icon-cover._3 {
    background: #0C4377;
}

.product-name-detail .detail-icon-cover._2 ion-icon {
    color: #D68B31;
}

.product-name-detail .detail-icon-cover._3 ion-icon {
    color: #548FAC;
}

.product-description p {
    font-size: 1.4rem;
    line-height: 1.8;
}

.cart-page .cart-quantity-control,
.cart-button .cart-quantity-control {
    /* background: #F1F4EB; */
    z-index: 10;
}

.body-content .cart-quantity-control,
.cart-button .cart-quantity-control {
    background: #F1F4EB;
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    padding: 13px 15px;
    text-align: center;
    border-radius: 50px;
}

.product-detail .cart-button button {
    padding: 14px 15px;
}

.body-content .cart-quantity-control svg {
    height: 2rem;
    width: auto;
}


/* cart-button */
.cart-button {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: #fff;
    padding: 15px 0px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, .4);
    padding: 15px 1.5%;
}

.product-detail .cart-button .cart-quantity-control svg {
    width: 2rem;
}

.product-detail .cart-button .cart-quantity-control h5 {
    font-size: 2rem;
}

.cart-button button {
    background: #096D55;
    padding: 20px 15px;
    width: 100%;
    border-radius: 50px;
    font-size: 1.6rem;
    color: #fff;
    border: 1px solid #096D55;
}

.body-content.contain {
    padding-bottom: 35%;
}


/* product detail */
.detail-action-button {
    /* position: absolute; */
    /* top: 10px;
    left: 1.5%; */
}

.detail-action-button .detail-action-cover {
    background: #fff;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, .2);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.detail-action-button svg {
    height: 20px;
    width: auto;
}

.detail-action-button svg.small {
    height: 15px;
}

.detail-action-button svg.medium {
    height: 18px;
}

.detail-action-button.right {
    left: unset;
    right: 0px;
}

.detail-action-button .grid-flex {
    grid-gap: 20px;
}


/* category-box */
.category-box-info {
    text-align: center;
}

.category-box {
    background: #FCE0A1;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.category-box img {
    width: auto;
    height: 3rem;
    display: block;
}

.category-box-info h4 {
    font-size: 1.1rem;
}


/* product-box-info */
.product-box-info {
    text-align: center;
    background: #fff;
    border-radius: 4px;
    padding: 15px 0px;
    padding-top: 0px;
}

.product-bar a {
    text-decoration: none;
}

.product-box-info .product-box-image {
    height: 12rem;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-box-info .product-box-image img {
    width: auto;
    height: 10rem;
}

.product-box-info h4 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    line-height: 1.6;
    font-family: 'Gilroy Medium';
}

.product-box-info p {
    font-size: 1.3rem;
    color: rgba(0, 0, 0, .7);
    margin-bottom: 10px;
}

.product-box-info h3 {
    font-size: 1.6rem;
    margin-bottom: 10px;
}

.currency {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.currency.small {
    font-size: 1rem;
}

.order-div-header-info .currency {
    font-size: 1rem;
}

.product-box-info button {
    background: #F1F4EB;
    padding: 10px 25px;
    font-size: 1.3rem;
    border: 1px solid #F1F4EB;
    border-radius: 4px;
    font-family: 'Gilroy Medium';
}


/* product-img-header */
.product-img-header {
    background: #F3F3F3;
    height: 12rem;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.product-img-header img {
    height: 8rem;
    width: auto;
}

.product-img-header .product-plus-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    border: 1px solid #fff;
    background: #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .2);
}

.product-img-header .product-plus-icon svg {
    height: 2rem;
    width: auto;
}

.product-data-cover h5.product-title {
    font-size: 1.2rem;
    margin-bottom: 5px;
    line-height: 1.4;
    font-family: 'Gilroy Medium';
}

.product-data-cover a {
    text-decoration: none;
}

.product-data-cover h3.product-amount {
    font-size: 1.3rem;
    margin-bottom: 5px;
}

.product-data-cover p.product-desc {
    font-size: 1.2rem;
    color: #258635;
    font-family: 'Gilroy Medium';
}


h3.tile-header {
    font-size: 1.4rem;
    margin-bottom: 0px;
}


/*  */
.order-div-box {
    border: 2px solid #E8E8E8;
    border-radius: 8px;
    padding: 15px 10px;
    margin-top: 20px;
}

.order-div-box:first-child {
    margin-top: 0px;
}

.order-div-box .order-div-header {
    display: flex;
    justify-content: space-between;
}

.order-div-box .order-div-header .order-div-header-info {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.order-div-box .order-div-header .order-div-header-info .order-div-header-image-box {
    background: #F3F3F3;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-div-box .order-div-header .order-div-header-info .order-div-header-image-box img {
    width: auto;
    height: 4rem;
}

.order-div-box .order-div-header .order-div-header-info ul {
    display: flex;
    grid-gap: 10px;
    margin-bottom: 10px;
}

.order-div-box .order-div-header .order-div-header-info ul li {
    list-style: none;
    font-size: 1.2rem;
    font-family: 'Gilroy Medium';
    color: rgba(0, 48, 50, .8);
}

.order-div-box .order-div-header .order-div-header-info h4 {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.order-div-box .order-div-header p {
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-family: 'Gilroy Medium';
    color: rgba(0, 48, 50, .8);
}

.order-div-box .order-div-header p.order-date {
    font-size: 1.3rem;
}

.order-div-box button {
    font-size: 1.2rem;
}


.bg-white {
    background: #fff;
    padding: 10px 0px 20px;
}

.checkout .bg-white ul li {
    line-height: 2.2;
}

.single-option {
    border: 2px solid var(--base-color);
    border: 2px solid rgba(5, 5, 5, 0.06);
    padding: 15px;
    font-size: 1.4rem;
    border-radius: 50px;
}

.single-option.active {
    border: 2px solid var(--base-color);
}

.checkout-bar {
    position: fixed;
    bottom: 0px;
    background: #fff;
    z-index: 2;
    width: 100%;
    padding: 10px;
}

.checkout-bar button {
    border-radius: 50px;
}

.wise9ja-content.checkout {
    height: max-content;
    padding-bottom: 80px;
}

.bg-white h3 {
    font-size: 1.5rem;
}

.bg-white h2,
.bg-white h4 {
    font-size: 1.3rem;
    font-family: 'Gilroy Medium';
}

.bg-white h4 {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.opt-box {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 3px solid rgba(5, 5, 5, 0.06);
    display: block;
}

.single-option.active .opt-box {
    border: 7px solid var(--base-color);
}

.checkout p {
    font-size: 1.3rem;
    line-height: 1.5;
    font-family: 'Gilroy Medium';
}

.checkout h3 {
    font-size: 1.4rem;
}

.checkout h3.selected_date {
    font-size: 1.2rem;
    line-height: 1.5;
    font-family: 'Gilroy Medium';
}

.checkout .btn-gray {
    font-size: 1.3rem;
    /* font-family: 'Gilroy Medium'; */
}

.ant-select-dropdown .ant-select-item-option-content {
    line-height: 3.2;
    font-size: 1.3rem;
}

.checkout span.currency {
    font-size: 1rem;
}